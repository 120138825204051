import React from 'react';
import "./wabutton.css"

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Reemplaza 'TUNUMERODETELEFONO' con tu número de WhatsApp
    const phoneNumber = '522381013593';
    const message = encodeURIComponent('Hola, me gustaría obtener más información sobre sus servicios');
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleWhatsAppClick}>
      <i className="fab fa-whatsapp"></i>
    </div>
  );
};

export default WhatsAppButton;
