import React from "react";

const SkillsScreen = () => {
    return (
        <section>
            <div className="container-moreinfo">
                <div className="column column-1">
                    <h1>SKILLS</h1>
                </div>
                <div className="column column-2">
                    <h1 className="skills-mobile">Skills</h1>
                    <div className="sk-cards">
                        <div className="sk-card">
                            <div>
                                <img className="sk-pic" src="media/html-logo.png" alt="" />
                                <div className="bubble">HTML</div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/css-logo.png" alt="" />
                                    <div className="bubble">CSS</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <img className="sk-pic" src="media/js-logo.png" alt="" />
                                <div className="bubble">JavaScript</div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/node-logo.png" alt="" />
                                    <div className="bubble">NodeJS</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <img className="sk-pic" src="media/react-logo.png" alt="" />
                                <div className="bubble">ReactJS</div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/firebase-logo.png" alt="" />
                                    <div className="bubble">Firebase</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <img className="sk-pic" src="media/mongo-logo.png" alt="" />
                                <div className="bubble">MongoDB</div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/ex-logo.png" alt="" />
                                    <div className="bubble">Express</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/git-logo.png" alt="" />
                                    <div className="bubble">Git</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/github.png" alt="" />
                                    <div className="bubble">Github</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/gitlab-logo.png" alt="" />
                                    <div className="bubble">Gitlab</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/npm-logo.png" alt="" />
                                    <div className="bubble">NPM</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/EN.png" alt="" />
                                    <div className="bubble">English</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/SP.png" alt="" />
                                    <div className="bubble">Spanish</div>
                                </div>
                            </div>
                        </div>
                        <div className="sk-card">
                            <div>
                                <div>
                                    <img className="sk-pic" src="media/FR.png" alt="" />
                                    <div className="bubble">French</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default SkillsScreen;