import React from 'react';
import '../../src/portfolio.css';

const projects = [
  {
    id: 6,
    title: 'GYM Website',
    description: 'GYM Landing Page donde los cliente pueden ver las instalaciones del gym, planes de suscripción, servicios que se ofrecen y pueden ponerse en contacto con el establecimiento para más información. En los botones de únete se puede implementar una página de suscripción para que los clientes dejen su información y puedan pagar por medio de stripe o paypal',
    image: '../media/gymweb.png',
  },
  {
    
    id: 1,
    title: 'SolCont',
    description: 'Sitio Web creado con React JS, de una firma contable que ofrece servicios presenciales y en línea, SEO Local',
    image: '../media/solcontweb.png',
    url: 'https://www.solcont.com.mx'
  },
  {
    id: 2,
    title: 'Chic Nail and Beauty Studio',
    description: 'This React-based website for a nail and beauty studio showcases services, social media links, a student enrollment form, and WhatsApp booking.',
    image: '../media/portfolio-1.png',
    url: 'https://camitolama.netlify.app'
  },
  {
    id: 3,
    title: 'Booking Restaurant',
    description: 'This project involves creating a modern and efficient booking system for restaurants using React as the front-end framework and Firebase as the backend solution. The system aims to streamline the reservation process, enhance the customer experience, and provide restaurant staff with a robust management tool.',
    image: '../media/portfolio-2.png',
  },
  {
    id: 4,
    title: 'Dashboard',
    description: 'The HTML, CSS, and JavaScript (JS) Vanilla Dashboard is a web application interface designed to provide users with quick access to key information and functionality. It offers a clean and user-friendly layout for monitoring and managing various aspects of a system, application, or business. API requests and asynchronous programming',
    image: '../media/portfolio-3.png',
  },
  {
    id: 5,
    title: 'E-Commerce Fullstack',
    description: 'The Full-Stack E-commerce Application is a comprehensive web platform that allows users to browse, search for, and purchase products online. This application leverages a modern tech stack, including React for the front-end, Express and Node.js for the back-end, and MongoDB for the database.',
    image: '../media/portfolio-4.png',
  },
];

const PortfolioScreen = () => {
  return (
    <div className="portfolio-screen">
      <div className='p-s-1'>
        <h1>Nuestra Trayectoria Creativa</h1>
        <h2>Creando experiencias</h2>
        <h4>Bienvenido a nuestro portafolio, donde cada campaña cuenta una historia de éxito</h4>
      </div>
      <div className="projects-container">
        {projects.map(project => (
          <div key={project.id} className="project-card">
            <img src={project.image} alt={project.title} />
            <div className="project-details">
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <a href={project.url} target="_blank" rel="noopener noreferrer">Visit Website</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioScreen;

