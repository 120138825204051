import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useHistory
import { db } from '../Firebase-config';
import { collection, addDoc } from 'firebase/firestore';

const ContactUs = () => {
  const navigate = useNavigate(); // Initialize useHistory

  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (
      !formData.name ||
      !formData.lastName ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      alert("Please fill out all fields before submitting.");
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      alert("Email must be in a valid email format");
    } else {
      const contactCollectionRef = collection(db, "contactUs");
      await addDoc(contactCollectionRef, formData);
      setSubmitted(true); // Moved setSubmitted here
      
      
      setFormData({
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
      
      // Navigate to Thank You page
      navigate('/thankyou');
    }
  };
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="bg-form">
      <div className="container-form">
        <h3>Solicita más Información</h3>
        <form onSubmit={handleSubmit}>
        <div className="mb-3">
            <label htmlFor="name" className="form-label">Nombre</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">Apellido</label>
            <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">Número de Teléfono + (Código de País)</label>
            <input type="tel" className="form-control" id="phoneNumber" placeholder="Ejemplo: México +52 555 555 5555" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Mensaje</label>
            <textarea className="form-control" id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
          </div>
          <div className="btn-container">
          <button type="submit" className="contact-btn">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
