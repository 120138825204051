import React from 'react';

function TermsAndConditions() {
    return (
        <div className="terms-container">
            <h1>Términos y Condiciones del Servicio</h1>
            <p>Estos Términos del Servicio describen los servicios de marketing digital que ofrecemos, cubriendo el uso de nuestros servicios, información de facturación, sus derechos de privacidad y la cancelación del servicio. Al utilizar nuestros servicios, acepta cumplir y quedar sujeto a los siguientes términos y condiciones:</p>

            <h2>1. Alcance de los Servicios</h2>
            <p>Nuestra agencia de marketing digital ofrece una variedad de servicios, incluyendo gestión de redes sociales, asistentes virtuales, diseño gráfico, desarrollo web, desarrollo de Software. Los detalles específicos de cada servicio se acordarán con el cliente antes de su implementación.</p>

            <h2>2. Cambios en los Servicios</h2>
            <p>Podemos cambiar los servicios ofrecidos, incluidas las tarifas, en cualquier momento. Si realizamos cambios, le proporcionaremos aviso por escrito con al menos 30 días de anticipación antes de la fecha efectiva.</p>

            <h2>3. Pagos</h2>
            <p>Los pagos por nuestros servicios deben realizarse por adelantado y tienen una fecha límite de hasta 10 días naturales desde la emisión de la factura o el inicio del ciclo de facturación. Después de estos 6 días se les enviará una notificación ofreciendo alternativas de pago (TDC, Transferencias, Facilidades de Pago). En dado de hacer caso omiso el día 10 de atraso se suspenderá el servicio y las campañas publicitarias. El balance seguirá activo y no podrá reanurarse el servicio sin el pago total del adeudo.</p>
            <p>Al ser suspendido el servicio para su reactivación habra un cargo por pago tardío del 5% del monto adeudado.</p>
            <p>Los precios serán en MXN para nuestros clientes en México incluyendo el IVA. Para nuestros clientes en otros países los precios se darán en USD.</p>
            <p>Los métodos de pago aceptados son Transferencias Bancarias SPEI, SWIFT, PAYPAL, Pagos con TDD, TDC, PreAuthorizados con cargo automático a TDC, TDD.</p>

            <h2>4. Uso de los Servicios</h2>
            <p>Los servicios de nuestra agencia de marketing digital solo pueden utilizarse para uso del cliente y en cumplimiento con la ley. Queda prohibido revender el servicio o utilizarlo con fines ilegales.</p>
            <p>isaSoft se deslinda de cualquier actividad ilegal incurrida por el cliente.</p>

            <h2>5. Cobertura de Servicio</h2>
            <p>Nuestros servicios de marketing digital tienen cobertura global. Limitandonos a la cominicación en Español, Inglés y Francés. </p>

            <h2>6. Facturación y Pagos</h2>
            <p>Recibirá una factura mensual por nuestros servicios, así como otros cargos administrativos.</p>

            <h2>8. Cancelación y Reembolsos</h2>
            <p>Si no está satisfecho con nuestros servicios, puede cancelar su acuerdo con previo aviso de 15 días antes del inicio del próximo ciclo de facturación. Los pagos recibidos no tienen devolución.</p>

            <p>Estos términos y condiciones del servicio forman parte de nuestro acuerdo completo con usted. Gracias por elegirnos como su agencia de marketing digital.</p>
        </div>
    );
}

export default TermsAndConditions;
