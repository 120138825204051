import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ContactUs from "./FormScreen";


const MainScreen = () => {

  const handleScrollToTop = () => {
    // Lógica para hacer scroll hacia arriba
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = '522381013593'; // Reemplaza con tu número de WhatsApp

    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const expertos = ["Gestión de Redes Sociales", "Marketing Digital", "Desarrollo de Software", "Asistentes Virtuales", "Creación de Contenido"];
    const intervalId = setInterval(() => {
      setVisibleIndex(prevIndex => (prevIndex + 1) % expertos.length);
    }, 2000); // Change every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollY(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scaleFactor = 1 + scrollY / 1000; // Adjust the divisor to control the scaling effect


  return (
    <>

      <section className="main">
        <div className="container-1">
          <div className="text-1">
            <h1 style={{ fontWeight: "800", fontSize: "44px" }}>
              Haz crecer tu <span style={{ fontWeight: "bold", color: "#FE5369" }}>empresa</span>
            </h1>
            <h3 className="changing-span">
              {' '}
              {["Gestión de Redes Sociales", "Marketing Digital", "Desarrollo de Software", "Asistentes Virtuales", "Creación de Contenido"].map((item, index) => (
                <span
                  key={index}
                  className={`expert ${index === visibleIndex ? 'visible' : 'hidden'}`}                >
                  {item}
                </span>
              ))}
            </h3>
            <br></br>
            <p className="s-1-p">
              Creamos soluciones para optimizar tus recursos y atraer clientes nuevos combinando el marketing digital y nuestros asistentes virtuales con Inteligencia Artificial.
            </p>
            <p className="s-1-p">

            </p>
            <br></br>
            <div style={{ display: "flex", gap: "12px" }}>
              <button className="styled-button" onClick={handleWhatsAppClick}>Contáctanos</button>
              <button className="styled-button2" onClick={handleWhatsAppClick}>Nuestros Clientes</button>
            </div>
          </div>
          <div className="container1-img">
            <img
              className="fsd-pic"
              src="./media/BOT1.png"
              alt=""
            />

          </div>
        </div>
      </section>
      <section className="main-part2">
        <div className="video-container">
          <div className="video-item">
            <video
              className="reel-video"
              src={process.env.PUBLIC_URL + "/media/fitobionBeritec.mp4"}
              controls
              muted
              loop
            ></video>
            <p className="video-caption">Creación de contenido atractivo para redes sociales</p>
          </div>
          <div className="video-item">
          <video
              className="reel-video"
              src={process.env.PUBLIC_URL + "/media/BrAgroVideo.mp4"}
              controls
              muted
              loop
            ></video>
            <p className="video-caption">Estrategias para aumentar tu presencia en línea</p>
          </div>
          <div className="video-item">
          <video
              className="reel-video"
              src={process.env.PUBLIC_URL + "/media/QueltexFe.mp4"}
              controls
              muted
              loop
            ></video>
            <p className="video-caption">Consejos para optimizar tu contenido visual</p>
          </div>
        </div>
      </section>
      <section className="w-u" id="about">
        <div>
          <h2 className="section-title">¿Por qué contratarnos?</h2>
          <p className="section-description">Lo que nos distingue es nuestro compromiso inquebrantable con la entrega de resultados reales. No somos solo proveedores de servicios, somos tus aliados en el camino hacia el éxito.</p>
        </div>
        <div className="w-u-cards">
          <div className="w-u-card">
            <img src="./media/img-card1.png" alt="img-card1"></img>
            <h3 className="w-u-card-title">Experiencia</h3>
            <p className="w-u-card-p">Nuestro equipo aporta una gran cantidad de conocimientos y una historia de éxitos a cada proyecto.</p>
          </div>
          <div className="w-u-card">
            <img src="./media/img-card2.png" alt="img-card1"></img>
            <h3 className="w-u-card-title">Centrados en el Cliente</h3>
            <p className="w-u-card-p">Tu éxito es nuestro éxito, y nos gusta ofrecer soluciones personalizadas que se adaptan a tus necesidades únicas.</p>
          </div>
          <div className="w-u-card">
            <img src="./media/img-card3.png" alt="img-card1"></img>
            <h3 className="w-u-card-title">Transparencia</h3>
            <p className="w-u-card-p">Creemos en una comunicación abierta, asegurándonos de que siempre estés al tanto del estado y prograso de tu proyecto.</p>
          </div>
        </div>
        <div>
          <div className="vm">
            <h4 className="vmtitle">Visión</h4>
            <p>Ser líderes en innovación y en la transformación digital, brindando soluciones de software avanzadas y mejorando la presencia de marca de las empresas a través de estrategias de marketing digital efectivas.</p>
          </div>
          <div className="vm">
            <h4 className="vmtitle">Misión</h4>
            <p>En isaSoft, nuestra misión es proporcionar soluciones de software integrales y servicios de marketing digital que permitan a las empresas prosperar en el mundo digital en constante evolución.</p>
          </div>
        </div>
      </section>
      <section className="our-services" id="services">
        <div>
          <h2 className="section-title">Nuestros Servicios</h2>
          <p className="section-description">Descubre nuestra amplia gama de servicios diseñados para impulsar tu empresa a nuevas alturas en el mundo digital.</p>
        </div>
        <div className="services-cards">
          <div className="service-card">
            <img src="./media/social-media.png" alt="Service 1" />
            <p>Manejo de Redes Sociales</p>
          </div>
          <div className="service-card">
            <img src="./media/digital-marketing.png" alt="Service 2" />
            <p>Marketing Digital</p>
          </div>
          <div className="service-card">
            <img src="/media/isaBot.png" alt="Service 3" />
            <p>isaBot (ChatBot WA)</p>
          </div>
          <div className="service-card">
            <img src="/media/web-dev.png" alt="Service 4" />
            <p>Diseño y Desarrollo web</p>
          </div>
          <div className="service-card">
            <img src="/media/ecommerce.png" alt="Service 5" />
            <p>Tiendas en Línea</p>
          </div>
          <div className="service-card">
            <img src="/media/softdev.png" alt="Service 6" />
            <p>Software Empresarial</p>
          </div>
        </div>

        <div className="service-bottom">
          <p className="section-description">
            Entendemos que cada proyecto es único y nos comprometemos a adaptar nuestros servicios a tus necesidades específicas. Para ofrecerte un presupuesto preciso y una solución que se alinee perfectamente con tus objetivos, te invitamos a ponerte en contacto con nosotros.
          </p>
          <button className="styled-button" onClick={handleWhatsAppClick}>Contáctanos</button>
        </div>
      </section>

      <section className="map-section">
        <div>
          <h2 className="section-title">Servicios Internacionales</h2>
          <p className="section-description">Ofrecemos servicios en inglés, español y francés para empresas en todo el mundo. Desde México, atendemos a clientes globales.</p>
        </div>
        <div className="map-container">
          <img src="/media/map.png" alt="map"></img>
        </div>

      </section>
      <section >
        <div className="formulario-promo">
          <ContactUs className="formulario-promo-1" />
        </div>
      </section>
      <div className="rocket" onClick={handleScrollToTop}>
        <img src="/media/rocket.png" alt="Rocket" />
      </div>
    </>

  )
}

export default MainScreen;