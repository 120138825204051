import React, { useState, useEffect } from 'react';
import { db } from '../Firebase-config';
import { collection, getDocs, onSnapshot, updateDoc, doc, deleteDoc } from 'firebase/firestore';


const ContactUsAdmin = () => {
    const contactCollectionRef = collection(db, "contactUs"); // Cambiar el nombre de la colección
    const [messages, setMessages] = useState([]); // Cambiar el nombre del estado
    const [selectedMessage, setSelectedMessage] = useState(null); // Cambiar el nombre del estado
    const [name, setName] = useState(""); // Cambiar el nombre del estado
    const [email, setEmail] = useState(""); // Cambiar el nombre del estado
    const [phone, setPhone] = useState(""); // Cambiar el nombre del estado
    const [message, setMessage] = useState(""); // Cambiar el nombre del estado

    useEffect(() => {
        const getMessages = async () => {
            const data = await getDocs(contactCollectionRef);
            setMessages(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        onSnapshot(contactCollectionRef, (snapshot) => {
            setMessages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });

        getMessages();
    }, [contactCollectionRef]);

    const updateMessage = async (id) => {
        const messageDoc = doc(db, "contactUs", id); // Cambiar el nombre de la colección
        const newFields = { name, email, phone, message }; // Cambiar los campos
        await updateDoc(messageDoc, newFields);
        setSelectedMessage(null);
    };

    const deleteMessage = async (id) => {
        const messageDoc = doc(db, "contactUs", id); // Cambiar el nombre de la colección
        await deleteDoc(messageDoc);
    };

    return (
        <div className="container" style={{minHeight:"100vh"}}>
            <h1 className="text-center my-5">Contact Us Messages</h1>
            <table className="table table-striped">
                {/* ... Table Headers ... */}
                <tbody>
                    {messages.map((message) => (
                        <tr key={message.id}>
                            <td>{message.name}</td>
                            <td>{message.lastName}</td>
                            <td>{message.phone}</td>
                            <td>{message.email}</td>
                            <td>{message.phoneNumber}</td>
                            <td>{message.message}</td>
                            <td className='btn-container'>
                                <button onClick={() => setSelectedMessage(message)} className="btn btn-primary"><i className='fa-solid fa-pen-to-square'></i></button>
                                <button onClick={() => deleteMessage(message.id)} className="btn btn-danger"><i className='fa-solid fa-trash'></i></button>
                            </td>
                        </tr>
                    ))}
                    {selectedMessage && (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <h3>Update Message</h3>
                            <input placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            <input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input placeholder="Phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                            <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} required />
                            <button className="btn btn-outline-success mr-2" onClick={() => updateMessage(selectedMessage.id)}>Update</button>
                            <button className="btn btn-outline-danger" onClick={() => setSelectedMessage(null)}>Cancel</button>
                        </div>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ContactUsAdmin;
