import React from 'react';
import { Link } from 'react-router-dom'; // Make sure to import Link from react-router-dom

const ThankYouPage = () => {
    return (
        <div style={{ minHeight: "80vh", display:"flex", flexDirection:"column", justifyContent:"center"}}>
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="mb-4">¡Gracias por Ponerte en Contacto!</h1>
                        <p>Apreciamos que hayas tomado el tiempo para enviarnos tus datos de contacto. Tu interés significa mucho para nosotros.</p>
                        <p>Nuestro equipo dedicado ya está trabajando, y prometemos responder a tu consulta lo más pronto posible. Mientras esperas, siéntete libre de explorar más acerca de nuestros servicios y ofertas en nuestro sitio web.</p>
                        <p>Si tienes asuntos urgentes, no dudes en llamarnos al <a href="tel:+522381013593">238-101-3593</a> o enviarnos un correo electrónico a <a href="mailto:info@isasoft.com.mx">info@isasoft.com.mx</a>.</p>
                        <p>Gracias una vez más por considerarnos. ¡Esperamos conectar contigo pronto!</p>
                        <p className="mb-0">Saludos cordiales,<br />Servicio al Cliente de isaSoft</p>
                        <div className="mt-4">
                            <Link to="/" className="btn btn-primary">Volver a la Página Principal</Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default ThankYouPage;
