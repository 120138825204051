import React from 'react';

const AvisoPrivacidad = () => {
  return (
    <div style={{padding:"5%"}}>
      <h2>Aviso de Privacidad</h2>
      <p>
        En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la "Ley"),
        isaSoft (en adelante "la Empresa"), con domicilio en 24 Sur 1115, Tehuacán, Puebla, hace de su conocimiento
        el presente aviso de privacidad, en el que se describen las prácticas de privacidad respecto a la información que recabamos
        de nuestros usuarios y clientes:
      </p>
      <h3>1. Datos Personales Recabados</h3>
      <p>
        La Empresa podrá recabar y procesar los siguientes datos personales:
        <ul>
          <li>Nombre completo</li>
          <li>Correo electrónico</li>
          <li>Número de teléfono</li>
          <li>Información de contacto</li>
          <li>Información sobre el negocio o empresa</li>
        </ul>
      </p>
      <h3>2. Finalidad del Tratamiento de Datos</h3>
      <p>
        Los datos personales recabados serán utilizados para las siguientes finalidades:
        <ul>
          <li>Contactar al usuario o cliente en relación con los servicios proporcionados por la Empresa.</li>
          <li>Procesar solicitudes, consultas o requerimientos realizados por el usuario o cliente.</li>
          <li>Enviar información relevante sobre nuestros productos o servicios.</li>
        </ul>
      </p>
      <h3>3. Protección de Datos</h3>
      <p>
        La Empresa implementa medidas de seguridad administrativas, técnicas y físicas para proteger los datos personales
        contra pérdida, robo, acceso no autorizado, divulgación, copia, uso o modificación no autorizados.
      </p>
      <h3>4. Uso de Datos</h3>
      <p>
        Los datos personales recabados por la Empresa serán utilizados exclusivamente por esta y no serán compartidos, vendidos,
        alquilados o transferidos a terceros sin su consentimiento explícito, excepto en los casos que establezca la Ley.
      </p>
      <h3>5. Derechos ARCO</h3>
      <p>
        De conformidad con la Ley, usted tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos
        personales (derechos ARCO). Para ejercer dichos derechos, o si tiene alguna pregunta o inquietud sobre nuestro tratamiento
        de sus datos personales, puede contactarnos a través de la dirección de correo electrónico info@isasoft.com.mx.
      </p>
      <h3>6. Cambios al Aviso de Privacidad</h3>
      <p>
        La Empresa se reserva el derecho de realizar en cualquier momento modificaciones o actualizaciones al presente aviso de
        privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u
        ofrecimiento de nuestros servicios o productos.
      </p>
      <p>
        Aceptación del Aviso de Privacidad: Al proporcionar sus datos personales a la Empresa, usted manifiesta haber leído, entendido
        y aceptado expresamente los términos y condiciones del presente aviso de privacidad.
      </p>
      <p>
        Fecha de última actualización: 01 de enero del 2024.
      </p>
      <p>
        Si tiene alguna pregunta sobre este aviso de privacidad, puede contactarnos a través de los datos de contacto proporcionados anteriormente.
      </p>
      <p>
        Atentamente,
        <br />
        Isaias Lezama Aponte. CEO Fundador de isaSoft
      </p>
    </div>
  );
};

export default AvisoPrivacidad;
