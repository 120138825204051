import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../Firebase-config';
import { collection, addDoc } from 'firebase/firestore';

const FilterForm = () => {
    const navigate = useNavigate();

    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
        businessType: "",
        monthlyIncome: "",
        advertisingBudget: "",
        monthlyInvestment: "",
        servicesOfInterest: {
            facebook: false,
            instagram: false,
            tiktok: false,
            website: false,
            chatbot: false
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !formData.fullName ||
            !formData.email ||
            !formData.phoneNumber ||
            !formData.businessType ||
            !formData.monthlyIncome ||
            !formData.advertisingBudget ||
            !formData.monthlyInvestment
        ) {
            alert("Por favor llena todos los campos.");
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
            alert("Email debe ser un formato valido example@example.com");
        } else {
            const contactCollectionRef = collection(db, "filterIsaSoft");
            await addDoc(contactCollectionRef, formData);
            setSubmitted(true);

            setFormData({
                fullName: "",
                email: "",
                phoneNumber: "",
                businessType: "",
                monthlyIncome: "",
                advertisingBudget: "",
                monthlyInvestment: "",
                servicesOfInterest: {
                    facebook: false,
                    instagram: false,
                    tiktok: false,
                    website: false,
                    chatbot: false
                }
            });

            navigate('/thankyou');
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...formData,
                servicesOfInterest: {
                    ...formData.servicesOfInterest,
                    [name]: checked
                }
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    return (
        <div className="bg-form">
            <div className="container-form">
                <h3>Formulario de Perfil</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="fullName" className="form-label">Nombre Completo</label>
                        <input type="text" className="form-control" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Correo Electrónico</label>
                        <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label">Número de Teléfono</label>
                        <input type="tel" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="businessType" className="form-label">Tipo de Negocio</label>
                        <input type="text" className="form-control" id="businessType" name="businessType" value={formData.businessType} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="monthlyIncome" className="form-label">Ingresos Brutos Aproximados al Mes*</label>
                        <input type="text" className="form-control" id="monthlyIncome" name="monthlyIncome" value={formData.monthlyIncome} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="advertisingBudget" className="form-label">Presupuesto Actual de Publicidad MXN$ o USD$</label>
                        <input type="text" className="form-control" id="advertisingBudget" name="advertisingBudget" value={formData.advertisingBudget} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="monthlyInvestment" className="form-label">Presupuesto en Mente para Invertir al Mes MXN$ o USD$</label>
                        <input type="text" className="form-control" id="monthlyInvestment" name="monthlyInvestment" value={formData.monthlyInvestment} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Servicios de Interés</label>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="facebook" name="facebook" checked={formData.servicesOfInterest.facebook} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="facebook">Facebook</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="instagram" name="instagram" checked={formData.servicesOfInterest.instagram} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="instagram">Instagram</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="tiktok" name="tiktok" checked={formData.servicesOfInterest.tiktok} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="tiktok">TikTok</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="website" name="website" checked={formData.servicesOfInterest.website} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="website">Website</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="chatbot" name="chatbot" checked={formData.servicesOfInterest.chatbot} onChange={handleChange} />
                            <label className="form-check-label" htmlFor="chatbot">ChatBot</label>
                        </div>
                    </div>

                    <p>* Esta opción es muy importante para poder brindarte un plan de acuerdo a tus necesidades especificas.</p>
                    <p>Al enviar el formulario aceptas haber leido nuestro <Link to={'/aviso-de-privacidad'}>Aviso de Privacidad</Link></p>
                    <div className="btn-container">
                        <button type="submit" className="contact-btn">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FilterForm;
