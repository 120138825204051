import { useState, React } from 'react';
import './App.css';
import { Link, BrowserRouter, Routes, Route, useHistory, useNavigate } from 'react-router-dom';
import SkillsScreen from './screens/SkillsScreen';
import ContactUs from './screens/FormScreen';
import ContactUsAdmin from './screens/ContactUsAdmin';
import MainScreen from './screens/MainScreen';
import LoginScreen from './screens/LoginScreen';
import ThankYouPage from './screens/ThankYou';
import Portfolio from './screens/PortfolioScreen';
import PromotionScreen from './screens/PromotionScreen';
import Snowfall from './screens/SnowFallComponent';
import WhatsAppButton from './component/WhatsAppButton';
import FilterForm from './screens/FilterForm';
import AvisoPrivacidad from './screens/AvisoPrivacidad';
import TermsAndConditions from './screens/TermsOfService';


function App() {


  return (

    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path='/' element={<MainScreen />} />
        <Route path='/more-info' element={<SkillsScreen />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/thankyou' element={<ThankYouPage />} />
        <Route path='/formulario-clientes' element={<FilterForm />}/>
        <Route path='/contact-us-a' element={<ContactUsAdmin />} />
        <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad/>} />
        <Route path='/terminos-de-servicio' element={<TermsAndConditions/>}/>

      </Routes>
      <WhatsAppButton/>
      <Footer />
    </BrowserRouter>
  );
}

const NavBar = () => {

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const navigate = useNavigate();

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');

    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleHomeLinkClick = () => {
    if (window.location.pathname === '/') {
      // If you're already on the home page, just scroll to the section
      scrollToAbout();
    } else {
      // If you're on a different page, navigate to the home page and then scroll
      navigate('/', { replace: true }); // Use the `replace` option
      scrollToAbout();
    }
  };


  return (
    <header className="header" id="site-header">
      <div className="header-content-wrapper">
        <div className="logo">
          <a href="/" className="full-block-link">
            <img src={process.env.PUBLIC_URL + '/media/isaSoftLogo.png'} alt="isaSoft Logo" />
          </a>
        </div>
        <nav id="primary-menu" className="primary-menu primary-menu-responsive">
          <ul className="primary-menu-menu">
            <li className="menu-item-has-children">
              <Link to="/">Inicio</Link>
            </li>
            <li className="">
              <Link to="/" onClick={handleHomeLinkClick}>Quiénes Somos</Link>
            </li>
            <li className="">
              <a href="#services">Servicios</a>
            </li>
            <li className="">
              <Link to="/portfolio">Portafolio</Link>
            </li>
            <li className="">
              <Link to="/contact">Contacto</Link>
            </li>
            <li className="scrollable-fix" />
          </ul>
        </nav>
        <div className="nav-menu">
          <div className="hamburger-icon" onClick={toggleMenu}>
            <i className="fas fa-bars"></i>
          </div>
          <ul className={`menu-items ${showMenu ? 'active' : ''}`}>
            <li className="close-button" onClick={toggleMenu}>
              <i className="fas fa-times"></i>
            </li>
            <li onClick={toggleMenu}><Link to="/">Inicio</Link></li>
            <li onClick={toggleMenu}><Link to="/portfolio">Portfolio</Link></li>
            <li onClick={toggleMenu}><Link to="/contact">Contact</Link></li>
          </ul>
        </div>

        {showMenu && <div className="overlay" onClick={toggleMenu}></div>}

      </div>
    </header>
  );
}

const Footer = () => {
  return (
    <div className="footer-dark" id="contact">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3 item">
              <h3>Services</h3>
              <ul>
                <li>Manejo de Redes Sociales</li>
                <li>Marketing Digital</li>
                <li>SEO - SEM</li>
                <li>Diseño y Desarrollo Web</li>
                <li>Tienda en Línea</li>
                <li>Software Empresarial</li>

              </ul>
            </div>
            <div className="col-sm-6 col-md-3 item">
              <h3>Contact</h3>
              <ul>
                <li>
                  <a href="tel:+522381013593">
                    <i className="fa-solid fa-phone" /> +52 238-101-3593
                  </a>
                </li>
                <li>
                  <a href="mailto:info@isasoft.com.mx">
                    <i className="fa-regular fa-envelope" /> info@isasoft.com.mx
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 item text">
              <h3>isaSoft</h3>
              <p>
                Agencia de Marketing Digital | Creación de Sitios Web | Estrategías de Marketing en Internet              </p>
            </div>
            <div className="col item social">
              <a href="https://www.instagram.com/isa.software.dev/">
                <i className="fa-brands fa-instagram" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=522381013593">
                <i className="fa-brands fa-whatsapp" />
              </a>
              <a href="mailto:info@isasoft.com.mx">
                <i className="fa-solid fa-envelope" />
              </a>
            </div>
          </div>
          <p className="copyright">isaSoft© Todos los derechos reservados 2024</p>
          <p style={{textAlign:"center"}}>Consulta los <Link to={"/terminos-de-servicio"}>Términos y Condiciones</Link> de nuestros servicios</p>
        </div>
      </footer>
    </div>

  )
}

export default App;
